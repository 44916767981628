<template>
  <section class="vid-title-sec">
    <!-- is active geldiğinde içeri gönder -->
    <div class="container">
      <form @submit.prevent="SET_MIDDLEWARE">
        <pdf-of-book @change:file="SET_FILE" :file="form.file"></pdf-of-book>
        <p v-if="fileArr === null && !edit" class="text-danger">
          *: {{ $t("fieldIsRequired") }}.
        </p>
        <div class="vid-title mt-5">
          <h2 class="title-hd">{{ $t("title") }} *</h2>
          <div class="form_field">
            <input
              type="text"
              required
              v-model="form.title"
              maxlength="100"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
        </div>
        <div class="vid-title">
          <h2 class="title-hd">{{ $t("description") }} *</h2>
          <div class="form_field">
            <textarea
              type="text"
              required
              v-model="form.description"
              maxlength="100"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
        </div>
        <div v-if="type === 2">
          <label for="">{{ $t("expiredAt") }} *</label>
          <date-picker
            :lang="{
              formatLocale: {
                firstDayOfWeek: 1,
              },
            }"
            class="w-100"
            required
            format="DD.MM.YYYY"
            :disabled-date="notBeforeToday"
            v-model="form.expiredAt"
            type="date"
          ></date-picker>
          <p v-if="form.expiredAt === null" class="text-danger">
            *: {{ $t("fieldIsRequired") }}.
          </p>
        </div>
        <div class="d-flex justify-content-end mt-4">
          <button @click.prevent="$emit('close')" class="btn btn-pill submit">
            {{ $t("close") }}
          </button>
          <button class="btn btn-pill donate ml-2">{{ $t("save") }}</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import PdfOfBook from "../NewProduct/PdfOfBook.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
export default {
  components: { PdfOfBook, DatePicker },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    modalRef: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
    event: {
      default: () => {},
    },
    video: {
      default: () => {},
    },
    item: {
      default: () => {},
    },
    type: {
      default: () => 1,
    },
  },
  data() {
    return {
      fileArr: null,
      form: {
        id: -1,
        title: "",
        description: "",
        type: 1,
        expiredAt: null,
        file: "",
      },
    };
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    SET_MIDDLEWARE(e) {
      e.preventDefault();
      if (
        (this.form.expiredAt === null && this.type === 2) ||
        (!this.edit && this.fileArr === null)
      )
        return;
      if (this.edit) this.UPDATE_FILE();
      else this.SEND_TO_NEW_FILE();
    },
    SET_FORM_DATA() {
      const fd = new FormData();
      fd.append("Title", this.form.title);
      fd.append("Description", this.form.description);
      if (this.fileArr !== null) fd.append("File", this.fileArr);
      fd.append("Type", this.type);
      if (this.type === 2)
        fd.append(
          "ExpiredAt",
          new Date(
            new Date(this.form.expiredAt).setHours(23, 59)
          ).toLocaleDateString("en-US", {
            hour12: false,
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        );
      else fd.append("ExpiredAt", "9999-12-31");
      return fd;
    },
    async SEND_TO_NEW_FILE() {
      this.SET_LOADING_MODAL(true);
      const item =
        this.type === 1
          ? this.product
          : this.type === 2
          ? this.event
          : this.video;
      const response = await this.$api.post(
        `Assignments?parentId=${item.id}`,
        this.SET_FORM_DATA()
      );
      if (response.message === "OK" && response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("insertIsSucceed"));
        this.$emit("refresh", "success", this.$t("insertIsSucceed"), this.form);
      } else {
        this.SET_ALERT_BOX("danger", this.$t("insertIsNotSucceed"));
      }
      // this.SET_LOADING_MODAL(false);
    },
    async UPDATE_FILE() {
      this.SET_LOADING_MODAL(true);

      const response = await this.$api.put(
        `Assignments/${this.form.id}`,
        this.SET_FORM_DATA()
      );
      if (response.message === "OK" && response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        this.$emit("refresh", "success", this.$t("updatedIsSucceed"), {
          ...this.form,
          expiredAt: new Date(
            new Date(this.form.expiredAt).setHours(23, 59)
          ).toLocaleDateString("en-US", {
            hour12: false,
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }),
        });
      } else {
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      }
      // this.SET_LOADING_MODAL(false);
    },
    SET_LOADING_MODAL(loading) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading,
        alertBox: {
          status: false,
          variant: "",
          message: "",
        },
      });
    },
    SET_ALERT_BOX(variant, message) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: true,
          variant,
          message,
        },
      });
    },
    SET_FILE(e) {
      this.form.file = e.uri; // video file'ı
      this.fileArr = e.file; /// video linki
    },
  },
  created() {
    if (this.edit)
      this.form = {
        ...this.item,
        expiredAt: new Date(this.item.expiredAt),
        file: this.$api.image(this.item.filePath),
      };
  },
};
</script>

<style></style>
